<template>
  <ib-card
    class="ai-requests-card-wrapper px-4 py-3"
    :animation="false"
  >
    <div class="d-flex justify-content-between">
      <div>
        <p class="title font-outfit-medium">
          {{ $t('aiAssistancePage.includedInSubscription') }}
        </p>
        <p
          class="requests-used m-0"
          :class="{'exceeded': isAiRequestsLimitExceeded}"
        >
          <span class="spent font-outfit-medium">{{ aiRequests.spent }}</span>
          {{ $t('aiAssistancePage.of') }} <span class="amount-requests font-outfit-medium">{{ aiRequests.requests }}</span> {{ $t('aiAssistancePage.aIRequestsUsed') }}
        </p>
      </div>

      <div class="d-flex flex-column text-right align-self-center">
        <span class="valid-until">{{ $t('aiAssistancePage.validUntil') }}</span>
        <span>{{ aiRequestsValidDate }}</span>
      </div>
    </div>

    <template v-if="aiRequests.additionalRequests > 0">
      <ib-divider block class="divider" />

      <div class="d-flex justify-content-between">
        <div>
          <p class="title font-outfit-medium">
            {{ $t('aiAssistancePage.additionalRequests') }}
          </p>
          <p
            class="requests-used m-0"
            :class="{'exceeded': isAiAdditionalRequestsLimitExceeded}"
          >
            <span class="spent font-outfit-medium">{{ aiRequests.additionalRequestsSpent }}</span>
            {{ $t('aiAssistancePage.of') }} <span class="amount-requests font-outfit-medium">{{ aiRequests.additionalRequests }}</span> {{ $t('aiAssistancePage.aIRequestsUsed') }}
          </p>
        </div>

        <div class="d-flex flex-column text-right align-self-center">
          <span class="valid-until">{{ $t('aiAssistancePage.validUntil') }}</span>
          <span>-</span>
        </div>
      </div>
    </template>
  </ib-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AiRequestsCard',

  computed: {
    ...mapGetters('user', [
      'isFreeTrial',
      'getAiRequests',
      'getCurrentUser',
      'isAiRequestsLimitExceeded',
      'isAiAdditionalRequestsLimitExceeded'
    ]),

    aiRequests () {
      return this.getAiRequests
    },

    aiRequestsValidDate () {
      const nextBillDate = this.getCurrentUser.subscription.nextBillDate

      if (nextBillDate) {
        return this.$moment(nextBillDate.date).format('DD MMM YYYY')
      }

      if (this.isFreeTrial) {
        return this.$moment(this.getCurrentUser.freeTrial).format('DD MMM YYYY')
      }

      return null
    }
  }
}
</script>

<style scoped lang="scss">
.ai-requests-card-wrapper {
  padding: 25px 23px 26px 22px;

  .title {
    margin: 0 0 9px 0;
    font-size: 20px;
  }

  .requests-used {
    font-size: 16px;
  }

  .amount-requests {
    font-size: 16px;
  }

  .exceeded {
    color: #FF7875;
  }

  .valid-until {
    opacity: .5;
    margin-bottom: 3px;
  }

  .divider {
    padding: 15px 0 14px;
  }

  .spent {
    font-size: 20px;
  }
}
</style>
