<template>
  <div class="ai-requests-product-card-wrapper d-flex justify-content-between" style="max-width: 720px;">
    <ib-card class="name-price-wrapper" :animation="false" style="flex: 1;">
      <div class="d-flex justify-content-between align-items-center">
        <div class="name">
          {{ value }} {{ $t('aiAssistancePage.aiRequests') }}
        </div>
        <div class="price">
          {{ price ? price : '-' }}
        </div>
      </div>
    </ib-card>

    <div class="d-flex" style="margin-left: 16px;">
      <ib-button :disabled="!price" @click="$emit('buy-now', productId)">
        {{ $t('aiAssistancePage.buyNow') }}
      </ib-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AiRequestsProductCard',

  props: {
    value: {
      type: Number,
      required: true
    },

    price: {
      validator: value => typeof value === 'string' || value === null,
      required: true
    },

    productId: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.ai-requests-product-card-wrapper {

  .name-price-wrapper {
    padding: 10px 22px;

    .name {
      font-size: 18px;
      font-weight: 400;
    }

    .price {
      font-size: 18px;
      font-weight: 700;
    }
  }

}
</style>
