<template>
  <div class="container-1200">
    <div class="content ml-auto mr-auto">
      <!-- Header -->
      <header class="d-flex justify-content-between align-center">
        <!-- Title -->
        <h1>{{ $t('aiAssistancePage.title') }}</h1>
        <!-- /Title -->
      </header>
      <!-- /Header -->

      <ai-requests-overview class="ai-requests-overview" />

      <ai-product-requests />
    </div>
  </div>
</template>

<script>
import AiProductRequests from '@/views/Home/AiAssistance/Components/AiProductRequests.vue'
import AiRequestsOverview from '@/views/Home/AiAssistance/Components/AiRequestsOverview.vue'

export default {
  name: 'AiAssistancePage',

  components: {
    AiProductRequests,
    AiRequestsOverview
  }
}
</script>

<style scoped lang="scss">
.container-1200 {
  height: 100vh;

  .ai-requests-overview {
    margin-bottom: 39px;
  }
}
</style>
