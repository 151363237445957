<template>
  <ib-dialog
    :visible="visible"
    width="550px"
    :show-close="false"
    empty-header
  >
    <div class="dialog-product-thank-you-wrapper d-flex align-items-center flex-column">

      <img
        class="payment-successful-img"
        src="@/assets/img/illustrations/payment-successful.svg"
        alt="payment-successful"
      >

      <p class="title-text">
        {{ $t('paymentSuccessful') }}
      </p>

      <p class="description">
        {{ $t('infoTextAiRequests') }}
      </p>

      <div>
        <ib-button
          variant="blue"
          @click="$emit('close')"
        >
          OK
        </ib-button>
      </div>
    </div>

  </ib-dialog>
</template>

<script>
export default {
  name: 'DialogProductThankYou',

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-product-thank-you-wrapper {
  .title-text {
    font-size: 32px;
    font-weight: 700;
    margin-top: 22px;
    margin-bottom: 19px;
  }

  .description {
    margin: 0 0 28px;
    text-align: center;
    font-size: 16px;
    opacity: 0.5;
  }
}
</style>
