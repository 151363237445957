<template>
  <div class="ai-requests-info-text-wrapper">
    <span class="ib-icon" :class="icon" /> {{ text }}
  </div>
</template>

<script>
export default {
  name: 'AiRequestsInfoText',

  props: {
    text: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      default: 'ib-icon-info'
    }
  }
}
</script>

<style scoped lang="scss">
.ai-requests-info-text-wrapper {
  opacity: .5;
  font-size: 14px;

  .ib-icon {
    margin-right: 3px;
  }
}
</style>
