<template>
  <div>
    <ai-requests-card class="mb-3" />

    <ai-requests-info-text
      :text="$t('aiAssistancePage.yourAiAssistanceCredit')"
    />
  </div>
</template>

<script>
import AiRequestsCard from '@/views/Home/AiAssistance/Components/AiRequestsCard.vue'
import AiRequestsInfoText from '@/views/Home/AiAssistance/Components/AiRequestsInfoText.vue'
import { mapActions } from 'vuex'

export default {
  name: 'AiRequestsOverview',

  components: {
    AiRequestsInfoText,
    AiRequestsCard
  },

  created () {
    this.fetchAiRequests()
  },

  methods: {
    ...mapActions('user', ['fetchAiRequests'])
  }
}
</script>
