<template>
  <div class="ai-product-requests-wrapper">
    <p class="title">
      {{ $t('aiAssistancePage.buyAdditionalAiRequests') }}
    </p>

    <ai-requests-product-card
      v-for="product in products"
      :key="product.id"
      class="mb-3"
      :value="product.value"
      :product-id="product.id"
      :price="product.price"
      @buy-now="buyNow($event)"
    />

    <ai-requests-info-text
      style="margin-top: 13px"
      :text="$t('aiAssistancePage.thisCreditWillBeUtilizedFirst')"
    />

    <dialog-product-thank-you
      :visible="dialogThankYouVisible"
      @close="onCloseDialogThankYou"
    />
  </div>
</template>

<script>
import AiRequestsInfoText from '@/views/Home/AiAssistance/Components/AiRequestsInfoText.vue'
import AiRequestsProductCard from '@/views/Home/AiAssistance/Components/AiRequestsProductCard.vue'
import DialogProductThankYou from '@/views/Home/AiAssistance/Components/Dialogs/DialogProductThankYou.vue'
import MixinPaddle from '@/mixins/paddle'
import { formatPrice } from '@/helpers/currencyHelper'
import { mapActions } from 'vuex'

export default {
  name: 'AiProductRequests',

  components: {
    DialogProductThankYou,
    AiRequestsProductCard,
    AiRequestsInfoText
  },

  mixins: [
    MixinPaddle
  ],

  data () {
    return {
      dialogThankYouVisible: false,
      products: [
        {
          id: 1,
          value: 100,
          price: null
        },
        {
          id: 2,
          value: 1000,
          price: null
        },
        {
          id: 3,
          value: 5000,
          price: null
        }
      ]
    }
  },

  created () {
    this.setupPaddle(this.fetchProducts)
  },

  methods: {
    ...mapActions('user', ['fetchAiRequests']),

    fetchProducts () {
      this.$http.get('/provider/product')
        .then(response => {
          this.products = response.data.payload
          this.getProducts()
        })
    },

    getProducts () {
      this.products.forEach(product => {
        // eslint-disable-next-line no-undef
        Paddle.Product.Prices(product.id, (prices) => {
          const { price, currency } = formatPrice(prices.price.net)
          product.price = `${currency}${Math.round(price)}`
        })
      })
    },

    buyNow (productId) {
      // eslint-disable-next-line no-undef
      Paddle.Checkout.open({
        product: productId,
        email: this.$store.state.user.email,
        passthrough: this.$store.state.user.id,
        locale: localStorage.getItem('lang'),
        successCallback: (data) => {
          this.fetchAiRequests().then(() => {
            this.dialogThankYouVisible = true
          })
        }
      })
    },

    async onCloseDialogThankYou () {
      this.dialogThankYouVisible = false
      await this.fetchAiRequests()
    }
  }
}
</script>

<style scoped lang="scss">
.ai-product-requests-wrapper {
  .title {
    margin: 0 0 23px;
    font-size: 24px;
    font-weight: 400;
  }
}
</style>
